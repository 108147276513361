import { Label, Stack } from '@fluentui/react';
import React from 'react'
import { IRequestHeaderDetail } from '../../../../models/applicationState';
import RequestHeaders from './requestHeaders';

interface IHeaderSettingsTabState {
    
}

interface IHeaderSettingsTabProps {
    headersJson: string,
    activeSettingsTab: string,
    updateFlag: number
}

export default class HeaderSettingsTab extends React.Component<IHeaderSettingsTabProps, IHeaderSettingsTabState> {

    requestHeadersRef = null;

    constructor(props) {
        super(props);
        this.getRequestHeaderData = this.getRequestHeaderData.bind(this);
    }

    public getRequestHeaderData = (): string => {
        return this.requestHeadersRef.getRequestHeaderData();
    }

    render() {
        const { headersJson, activeSettingsTab } = this.props;
        return (
            <Stack
                tokens={{
                    childrenGap: 10
                }}
                style={activeSettingsTab !== 'Headers' ? { display: 'none' } : {}}
            >
                <Stack.Item align="stretch">
                    <RequestHeaders
                        headersJson={headersJson}
                        updateFlag={this.props.updateFlag}
                        ref={(el) => (this.requestHeadersRef = el)}
                    />
                </Stack.Item>                
            </Stack>
        )
    }
}
