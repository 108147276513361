import React from 'react';
import { ChoiceGroup, Dropdown, IChoiceGroupOption, IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import { IBodyData, IValidationError } from "../../../../models/applicationState";

interface IBodySettingsTabState {
    bodyType: string,
    bodyTextType: string,
    bodyText: string
}

interface IBodySettingsTabProps {
    bodyType: string,
    bodyTextType: string,
    bodyText: string,
    activeSettingsTab: string,
    updateFlag: number,
    validationError: IValidationError
}

export default class BodySettingsTab extends React.Component<IBodySettingsTabProps, IBodySettingsTabState> {
    
    constructor(props) {
        super(props);
        this.state = {
            bodyType: this.props.bodyType || 'raw',
            bodyTextType: this.props.bodyTextType || 'JSON',
            bodyText: this.props.bodyText || ''
        }
        this.onBodyTypeChanged = this.onBodyTypeChanged.bind(this);
        this.onBodyTextTypeChanged = this.onBodyTextTypeChanged.bind(this);
        this.onBodyTextChanged = this.onBodyTextChanged.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IBodySettingsTabProps>) {
        if (prevProps.updateFlag !== this.props.updateFlag) {
            this.setState({
                bodyType: this.props.bodyType,
                bodyTextType: this.props.bodyTextType,
                bodyText: this.props.bodyText
            });
        }
    }

    bodyTypes = [
        //{ key: 'form-data', text: 'form-data' },
        //{ key: 'x-www-form-urlencoded', text: 'x-www-form-urlencoded' },
        { key: 'raw', text: 'raw' }
    ]

    bodyTextTypes = [
        { key: 'JSON', text: 'JSON' }
    ]

    private onBodyTypeChanged = (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
        if(option) {
            this.setState({
                bodyType: option.key
            })
        }
    }

    private onBodyTextTypeChanged = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        if (item) {
            this.setState({
                bodyTextType: item.key as string
            })
        }
    }

    private onBodyTextChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({
            bodyText: newValue
        })
    }

    public getBodyData = (): IBodyData => {
        return {
            bodyText: this.state.bodyText,
            bodyTextType: this.state.bodyTextType,
            bodyType: this.state.bodyType
        }
    }

    render() {
        const { bodyType, bodyTextType, bodyText } = this.state;
        const bodyTextErrors = !!this.props.validationError['bodyText'] ? this.props.validationError['bodyText'] : null;
        let bodyTextError = '';
        if (bodyTextErrors) {
            bodyTextError = bodyTextErrors.join('. ');
        }
        return (
            <Stack
                tokens={{
                    childrenGap: 10
                }}
                style={this.props.activeSettingsTab !== 'Body' ? { display: 'none' } : {}}
            >
                <Stack.Item>
                    <Stack horizontal>
                        <ChoiceGroup
                            className="inlineflex"
                            options={this.bodyTypes}
                            selectedKey={bodyType}
                            onChange={this.onBodyTypeChanged}
                        />
                        {bodyType === 'raw' &&
                            <Dropdown options={this.bodyTextTypes} selectedKey={bodyTextType} onChange={this.onBodyTextTypeChanged } />
                        }
                    </Stack>
                    
                </Stack.Item>
                <Stack.Item>
                    <TextField multiline rows={14} resizable={false} label="@data will contain the tags as JSON array" placeholder="{ data: @data }" value={bodyText} onChange={this.onBodyTextChanged} errorMessage={bodyTextError} />
                </Stack.Item>
            </Stack>
        )
    }
}
