import React from "react";
import {
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
    IIconProps,
    TextField,
    DatePicker,
    DayOfWeek,
    Text
}
    from "@fluentui/react";
import { DefaultButton, PrimaryButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react';
import moment from 'moment';

export interface IEditorSideBarFilterModalProps {
    showFilterModal: boolean;
    onToggleFilterModal: () => void;
    filterSelectedStatusKeys: string[];
    filterText: string;
    filterStartDate?: Date;
    filterEndDate?: Date;
    onFilterPreviewSelectedStatusOptionChanged: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void;
    onFilterPreviewTextChanged: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    onFilterStartDateChanged: (date: Date | null | undefined) => void;
    onFilterEndDateChanged: (date: Date | null | undefined) => void;
    onApplyFilter: () => void;
    onClearFilter: () => void;
    errors: string[];
}

export interface IEditorSideBarFilterModalState {
    
}

export default class EditorSideBarFilterModal extends React.Component<IEditorSideBarFilterModalProps, IEditorSideBarFilterModalState> {

    theme = getTheme();

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    filterStatuses = [
        { key: 'NotVisitedHeader', text: 'Not Visited', itemType: DropdownMenuItemType.Header },
        { text: 'Not Visited', key: 'asset-0'},
        { key: 'VisitedHeader', text: 'Visited', itemType: DropdownMenuItemType.Header },
        { text: 'Visited', key: 'asset-1' },
        { key: 'TaggedHeader', text: 'Tagged', itemType: DropdownMenuItemType.Header },
        { text: 'Auto Labeled', key: 'label-3' },
        { text: 'Auto Labeled and Adjusted', key: 'label-4' },
        { text: 'Trained', key: 'label-2' },
        { text: 'Manually Labeled', key: 'label-1' }];

    

    contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '300px',
            width: '700px'
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: this.theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: this.theme.palette.neutralDark,
        },
    };

    cancelIcon: IIconProps = { iconName: 'Cancel' };

    render() {
        
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.showFilterModal}
                    isBlocking={true}
                    onDismiss={this.props.onToggleFilterModal}
                    containerClassName={this.contentStyles.container}
                >
                    <div className={this.contentStyles.header}>
                        <h2 className={this.contentStyles.heading}>
                            Filter Document Preview
                        </h2>
                        <IconButton
                            styles={this.iconButtonStyles}
                            iconProps={this.cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={this.props.onToggleFilterModal}
                        />
                    </div>
                    <div className={this.contentStyles.body}>
                        <Stack
                            tokens={{
                                childrenGap: 10,
                                padding: 10,
                            }}
                        >
                            <Stack.Item align="stretch">
                                <Dropdown
                                    placeholder="Select status"
                                    label="Select status"
                                    multiSelect
                                    options={this.filterStatuses}
                                    onChange={this.props.onFilterPreviewSelectedStatusOptionChanged}
                                    selectedKeys={[...this.props.filterSelectedStatusKeys]} 
                                />
                            </Stack.Item>
                            <Stack.Item align="stretch">
                                <TextField label="File name" value={this.props.filterText} onChange={this.props.onFilterPreviewTextChanged} />
                            </Stack.Item>
                            <span>Date uploaded</span>
                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
                                <Stack.Item grow={6}>
                                    <DatePicker
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        showWeekNumbers={false}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Select a start date"
                                        ariaLabel="Select a start date"
                                        onSelectDate={this.props.onFilterStartDateChanged}
                                        value={this.props.filterStartDate}
                                    />
                                </Stack.Item>
                                <Stack.Item grow={6}>
                                    <DatePicker
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        showWeekNumbers={false}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Select an end date"
                                        ariaLabel="Select an end date"
                                        onSelectDate={this.props.onFilterEndDateChanged}
                                        value={this.props.filterEndDate}
                                    />
                                </Stack.Item>                                
                            </Stack>
                            {this.props.errors && this.props.errors.length > 0 &&
                                <span style={{ color: "#FF0000"}}>
                                    {
                                        this.props.errors.map(err => {
                                            return (
                                                <Text key={err} variant="small" >
                                                    *{err}
                                                </Text>
                                            )
                                        })
                                    }
                                </span>
                            }
                            <Stack horizontal horizontalAlign="end" tokens={{childrenGap: 5}}>
                                <PrimaryButton text="Filter" allowDisabledFocus onClick={this.props.onApplyFilter} disabled={this.props.errors && this.props.errors.length > 0} />
                                <DefaultButton text="Clear" allowDisabledFocus onClick={this.props.onClearFilter} />
                            </Stack>
                        </Stack>
                    </div>
                </Modal>                
            </React.Fragment>
        )
    }
}
