import React from "react";
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';

export interface IBasicAuthProps {
    credentialsJson: string
}

export interface IBasicAuthState {
    username: string,
    password: string
}

export default class BasicAuth extends React.Component<IBasicAuthProps, IBasicAuthState> {
    stackTokens = { childrenGap: 50 };
    stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } },
    };

    constructor(props) {
        super(props);
        let username = '';
        let password = '';
        if (props.credentialsJson) {
            const credJson = JSON.parse(props.credentialsJson);
            username = credJson.username;
            password = credJson.password;
        }
        this.state = {
            username,
            password
        }

        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.getCredentialsJson = this.getCredentialsJson.bind(this);
    }

    onUsernameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({
            username: newValue
        });
    }

    onPasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({
            password: newValue
        });
    }

    public getCredentialsJson = (): string => {
        let credentials = {
            username: this.state.username,
            password: this.state.password
        };
        return JSON.stringify(credentials);
    }

    render() {
        return (
            <Stack horizontal tokens={this.stackTokens} styles={this.stackStyles}>
                <Stack {...this.columnProps}>
                    <TextField label="Username" required value={this.state.username} onChange={this.onUsernameChange} />
                    <TextField
                        label="Password"
                        type="password"
                        required
                        value={this.state.password}
                        onChange={this.onPasswordChange}
                    />
                </Stack>
            </Stack>
        )
    }
}
