import React from "react";
import { IAsset } from "../../../../models/applicationState";
import { FontIcon } from "@fluentui/react";
import { AssetPreview, ContentSource } from "../../common/assetPreview/assetPreview";

export interface IEditorSideBarItemProps {
    asset: IAsset;
    style: any;
    getAssetCssClassNames: (asset: IAsset, selectedAsset?: IAsset) => string;
    selectedAsset: IAsset;
    onAssetClicked: (asset: IAsset) => void;
    deleteAssetOnSelect: (event, asset: IAsset) => void;
    download: (url, name, event) => void;
    checkboxRowRenderer: (asset: IAsset) => JSX.Element;
    renderBadges: (asset: IAsset) => JSX.Element;
    onAssetLoaded?: (asset: IAsset, ContentSource: ContentSource) => void;
    assetTitle: string;
}

export interface IEditorSideBarItemState {
    isVisible: boolean
}

export default class EditorSideBarItem extends React.Component<IEditorSideBarItemProps, IEditorSideBarItemState> {
    private panelRef: React.RefObject<any> = React.createRef();
    constructor(props: IEditorSideBarItemProps) {
        super(props);

        this.state = {
            isVisible: false
        };
        this.initializeIntersectionObserver = this.initializeIntersectionObserver.bind(this);
    }

    componentDidMount() {
        this.initializeIntersectionObserver();
    }

    initializeIntersectionObserver() {
        if (!('IntersectionObserver' in window)) {
            console.error('IntersectionObserver not supported');
        }

        const intersectionObserver = new IntersectionObserver(
            (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                entries.forEach((entry, i) => {
                    if (!entry.isIntersecting) return;

                    observer.unobserve(entry.target);

                    this.setState({
                        isVisible: true,
                    });
                });
            }
        );

        intersectionObserver.observe(this.panelRef.current);
    }

    public render() {
        const { asset, style, getAssetCssClassNames, selectedAsset, onAssetClicked, deleteAssetOnSelect, download, checkboxRowRenderer, renderBadges, onAssetLoaded, assetTitle } = this.props;
        return (            
            <div
                ref={this.panelRef}
                key={asset.id}
                style={style}
                role="row"
                className={getAssetCssClassNames(asset, selectedAsset)}
                onClick={() => onAssetClicked(asset)}
            >
                {this.state.isVisible &&
                    <React.Fragment>
                        <div className="asset-item-image" role="gridcell">
                            <FontIcon
                                iconName="delete"
                                style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    left: "10px",
                                    zIndex: 10,
                                    color: "#ee6565",
                                }}
                                title="Delete Document"
                                onClick={(event) =>
                                    deleteAssetOnSelect(event, asset)
                                }
                            />
                            <FontIcon
                                iconName="download"
                                style={{
                                    position: "absolute",
                                    top: "5px",
                                    left: "10px",
                                    zIndex: 10,
                                    color: "rgb(10, 181, 10)",
                                }}
                                title="Download Document"
                                onClick={(event) =>
                                    download(asset.path, asset.name, event)
                                }
                            />
                            {checkboxRowRenderer(asset)}
                            {renderBadges(asset)}
                            <AssetPreview
                                asset={asset}
                                onLoaded={onAssetLoaded}
                            />
                        </div>
                        <div className="asset-item-metadata" role="rowheader">
                            <span className="asset-filename" title={assetTitle}>
                                {asset.name.slice(
                                    asset.name.lastIndexOf("/") + 1,
                                    asset.name.length
                                )}
                            </span>
                            {asset.size && (
                                <span>
                                    {asset.size.width} x {asset.size.height}
                                </span>
                            )}
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}
