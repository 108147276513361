import React from "react";
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IconButton } from 'office-ui-fabric-react';
import './oAuth2Detail.scss';
import { IAuthData, IValidationError } from "../../../../models/applicationState";

export interface IkvpBody {
    id: number,
    key: string,
    value: string
}

export interface IOAuth2Props {
    credentialsJson: string,
    authEndpointUrl: string,
    updateFlag: number,
    validationError: IValidationError
}

export interface IOAuth2State {
    kvpBodies: Array<IkvpBody>,
    authEndpointUrl: string
}

export default class OAuth2Detail extends React.Component<IOAuth2Props, IOAuth2State> {
    stackTokens = { childrenGap: 50 };
    stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 550 } },
    };
    credColumns: IColumn[] = [
        {
            key: 'key',
            name: 'Key',
            fieldName: 'key',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            onRender: (item: IkvpBody) => {
                return <TextField placeholder="Key" value={item.key} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemKeyChanged(item.id, newValue) } />;
            },
            isPadded: true,
        },
        {
            key: 'value',
            name: 'Value',
            fieldName: 'value',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            onRender: (item: IkvpBody) => {
                return <TextField placeholder="Value" value={item.value} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemValueChanged(item.id, newValue)} />;
            },
            isPadded: true,
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 70,
            maxWidth: 90,
            onRender: (item: IkvpBody) => {
                return <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Emoji" />;
            },
            isPadded: true,
        }
    ];

    initKvpBodies: Array<IkvpBody> = [
        { id: 1, key: 'grant_type', value: '' },
        { id: 2, key: 'client_id', value: '' },
        { id: 3, key: 'client_secret', value: '' },
        { id: 4, key: 'scope', value: '' }
    ]

    constructor(props) {
        super(props);
        let credJsonDetails: Array<IkvpBody> = this.getCredentialsJsonDetails();

        this.state = {
            kvpBodies: credJsonDetails,
            authEndpointUrl: props.authEndpointUrl || ''
        }

        this.onItemKeyChanged = this.onItemKeyChanged.bind(this);
        this.onItemValueChanged = this.onItemValueChanged.bind(this);
        this.onEndpointUrlChange = this.onEndpointUrlChange.bind(this);
        this.deleteKvp = this.deleteKvp.bind(this);
        this.newKvp = this.newKvp.bind(this);
        this.getCredentialsJsonDetails = this.getCredentialsJsonDetails.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IOAuth2Props>) {
        if (prevProps.updateFlag !== this.props.updateFlag) {
            const credJsonDetails = this.getCredentialsJsonDetails();
            this.setState({
                kvpBodies: credJsonDetails,
                authEndpointUrl: this.props.authEndpointUrl
            });
        }
    }

    private getCredentialsJsonDetails = (): Array<IkvpBody> => {
        let credJsonDetails: Array<IkvpBody> = [];
        if (this.props.credentialsJson) {
            try {
                credJsonDetails = JSON.parse(this.props.credentialsJson) as Array<IkvpBody>;
            }
            catch {

            }
        }
        else {
            credJsonDetails = this.initKvpBodies;
        }
        return credJsonDetails;
    }

    //onUsernameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    //    this.setState({
    //        username: newValue
    //    });
    //}

    //onPasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    //    this.setState({
    //        password: newValue
    //    });
    //}
    onItemKeyChanged = (itemId: number, newItemKey: string) => {
        let { kvpBodies } = this.state;
        let kvpBody = kvpBodies.find(r => r.id === itemId);
        if (kvpBody) {
            kvpBody.key = newItemKey;
        }
        this.setState({
            kvpBodies
        });
    }

    onItemValueChanged = (itemId: number, newItemValue: string) => {
        let { kvpBodies } = this.state;
        let kvpBody = kvpBodies.find(r => r.id === itemId);
        if (kvpBody) {
            kvpBody.value = newItemValue;
        }
        this.setState({
            kvpBodies
        });
    }

    onEndpointUrlChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({
            authEndpointUrl: newValue
        });
    }

    public getAuthData = (): IAuthData => {
        return {
            authType: 0,
            authEndpointUrl: this.state.authEndpointUrl,
            credentialsJson: JSON.stringify(this.state.kvpBodies)
        }
    }

    private deleteKvp = (itemId: number): void => {
        let { kvpBodies } = this.state;
        kvpBodies = kvpBodies.filter(r => r.id !== itemId);
        this.setState({
            kvpBodies
        });
    }

    private newKvp = (): void => {
        let { kvpBodies } = this.state;
        kvpBodies.push({ id: kvpBodies.length + 1, key: '', value: '' });
        this.setState({
            kvpBodies
        });
    }

    render() {
        const { kvpBodies, authEndpointUrl } = this.state;
        const authUrlErrors = !!this.props.validationError['authEndpointUrl'] ? this.props.validationError['authEndpointUrl'] : null;
        let authUrlError = '';
        if (authUrlErrors) {
            authUrlError = authUrlErrors.join('. ');
        }
        return (
            <React.Fragment>
                <Stack.Item align="stretch">
                    <TextField label="Authentication Endpoint URL" required value={authEndpointUrl} onChange={this.onEndpointUrlChange} errorMessage={authUrlError} />
                </Stack.Item>
                <Stack.Item align="stretch">
                    <table style={{width: '100%'}}>
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th><IconButton iconProps={{ iconName: 'Add' }} title="New" ariaLabel="New" onClick={this.newKvp} /></th>
                        </tr>
                        <tbody>
                            {kvpBodies &&
                                kvpBodies.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <TextField placeholder="Key" value={item.key} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemKeyChanged(item.id, newValue)} />
                                            </td>
                                            <td>
                                                <TextField placeholder="Value" value={item.value} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemValueChanged(item.id, newValue)} />
                                            </td>
                                            <td>
                                                <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => this.deleteKvp(item.id)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Stack.Item>                
            </React.Fragment>
        )
    }
}
