import React, { useState } from "react";
import {
    getDarkTheme,
    getPrimaryBlueTheme,
    getPrimaryGreenTheme,
} from "../../../../common/themes";
import {
    Customizer,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogType,
    FontIcon,
    ICustomizations,
    Label,
    PrimaryButton,
    Spinner,
    SpinnerSize,
} from "@fluentui/react";
import {
    getCookieAdmin,
    getCookieEmail,
    parseEndpointByType,
} from "../jsonSchemaFormHelper";
import axios from "axios";
import {
    IGetCompaniesCommand,
    IGetSubscriptionSummaryCommand,
} from "../../../../models/applicationState";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

/**
 * Properties for Alert Component
 * @member closeButtonText - Text displayed on 'Close' button. Default 'OK'
 * @member onClose - Function to execute on alert close
 * @member confirmButtonTheme - Theme of 'Confirm' button
 */
export interface IAdminDashboardDialogProps {
    show?: boolean;
    onClose?: () => void;
}

/**
 * State for Alert Component
 * @member params - Arguments passed in the open command
 */
export interface IAdminDashboardDialogState {
    loading: boolean;
    projectSubscription?: any;
    companies?: any[];
    loadingMessage: string;
    dropdownOpen: boolean;
    selectedCompany: any;
}

/**
 * @name - Alert
 * @description - Generic Alert dialog
 */
export default class AdminDashboardDialog extends React.Component<
    IAdminDashboardDialogProps,
    IAdminDashboardDialogState
> {
    // hachet
    constructor(props, context) {
        super(props, context);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onReloadClick = this.onReloadClick.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.state = {
            loading: false,
            projectSubscription: {},
            companies: [],
            loadingMessage: "Loading, please wait...",
            dropdownOpen: false,
            selectedCompany: null,
        };
    }

    async componentDidMount() {
        if (getCookieAdmin()) {
            await this.loadCompanies();
        }
    }

    private async loadCompanies() {
        this.setState({
            loading: true,
        });
        try {
            const isAdmin = getCookieAdmin();

            const response = await axios.post(
                parseEndpointByType("GetCompanies"),
                {
                    isAdmin,
                } as IGetCompaniesCommand,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        withCredentials: "true",
                    },
                }
            );
            const data = response.data;
            if (data.successful) {
                this.setState({
                    companies: data.items,
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    private async loadSubscription(
        reCalculate: boolean = false,
        emailValue: string = ""
    ) {
        const email = emailValue !== "" ? emailValue : getCookieEmail();
        if (email) {
            this.setState({
                loading: true,
            });
            try {
                const response = await axios.post(
                    parseEndpointByType("SubscriptionSummary"),
                    { email, reCalculate } as IGetSubscriptionSummaryCommand,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            withCredentials: "true",
                        },
                    }
                );
                const data = response.data;
                if (data.successful) {
                    this.setState({
                        projectSubscription: data,
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    private renderProjects = () => {
        return this.state.projectSubscription.projects.map((project, index) => (
            <tr key={index}>
                <td style={{ padding: "5px" }}>{project.name}</td>
                <td style={{ textAlign: "center", padding: "5px" }}>
                    {project.files}
                </td>
                <td style={{ textAlign: "center", padding: "5px" }}>
                    {project.pages}
                </td>
            </tr>
        ));
    };

    private calculateTotal(): number {
        if (
            this.state.projectSubscription &&
            this.state.projectSubscription.projects
        ) {
            return this.state.projectSubscription.projects
                .map((p) => p.pages)
                .reduce((a, b) => a + b, 0);
        }
        return 0;
    }

    private dropdownToggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    private onSelect(value: any) {
        if (
            this.state.selectedCompany &&
            this.state.selectedCompany.id === value.id
        ) {
            return;
        }

        this.setState({
            selectedCompany: value,
        });

        this.loadSubscription(true, value.email);
    }

    public render() {
        const dark: ICustomizations = {
            settings: {
                theme: getDarkTheme(),
            },
            scopedSettings: {},
        };

        return (
            <Customizer {...dark}>
                <Dialog
                    hidden={!this.props.show}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Subscription Report",
                        isMultiline: true,
                    }}
                    minWidth="700px"
                    modalProps={{
                        isBlocking: false,
                    }}
                >
                    <DialogContent>
                        <div className="row p-0 m-0">
                            <div className="col-sm-12 p-0 m-0">
                                {this.state.loading && (
                                    <div style={{ zIndex: 1 }}>
                                        <div className="project-saving-spinner">
                                            <Label className="p-0"></Label>
                                            <h3>
                                                <Spinner
                                                    size={SpinnerSize.large}
                                                    label={
                                                        this.state
                                                            .loadingMessage
                                                    }
                                                    labelPosition="right"
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                )}

                                {!this.state.loading && this.state.companies && (
                                    <Dropdown
                                        isOpen={this.state.dropdownOpen}
                                        toggle={() => this.dropdownToggle()}
                                    >
                                        <DropdownToggle caret>
                                            {this.state.selectedCompany
                                                ? this.state.selectedCompany
                                                      .name
                                                : "Select Company"}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.companies.length &&
                                                this.state.companies.map(
                                                    (c, index) => (
                                                        <DropdownItem
                                                            key={index}
                                                            active={
                                                                this.state
                                                                    .selectedCompany &&
                                                                this.state
                                                                    .selectedCompany
                                                                    .id === c.id
                                                            }
                                                            onClick={() =>
                                                                this.onSelect(c)
                                                            }
                                                        >
                                                            {c.name}
                                                        </DropdownItem>
                                                    )
                                                )}
                                        </DropdownMenu>
                                    </Dropdown>
                                )}

                                {!this.state.loading && (
                                    <>
                                        <div
                                            style={{
                                                padding: "5px",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <b>Company Name</b>&nbsp;:&nbsp;
                                            {
                                                this.state.projectSubscription
                                                    .companyName
                                            }
                                        </div>
                                        <div style={{ padding: "5px" }}>
                                            <b>User Name</b>&nbsp;:&nbsp;
                                            {
                                                this.state.projectSubscription
                                                    .userName
                                            }
                                        </div>
                                        <div style={{ padding: "5px" }}>
                                            <b>Email</b>&nbsp;:&nbsp;
                                            {
                                                this.state.projectSubscription
                                                    .email
                                            }
                                        </div>
                                        <hr
                                            style={{ border: "solid 1px #333" }}
                                        />
                                        <table style={{ width: "400px" }}>
                                            <tbody>
                                                <tr>
                                                    <th
                                                        style={{
                                                            padding: "5px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        <b>Project Name</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "5px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        <b>
                                                            <FontIcon
                                                                iconName="Info"
                                                                id="pickfiles"
                                                                title="Total files uploaded"
                                                            />{" "}
                                                            Total Files
                                                        </b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "5px",
                                                            width: "300px",
                                                        }}
                                                    >
                                                        <b>
                                                            <FontIcon
                                                                iconName="Info"
                                                                id="pickfiles"
                                                                title="Total pages that are processed by the OCR"
                                                            />{" "}
                                                            Billable Pages
                                                        </b>
                                                    </th>
                                                </tr>
                                                {this.state.projectSubscription
                                                    .projects &&
                                                    this.renderProjects()}
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        style={{
                                                            textAlign: "right",
                                                            paddingTop: "20px",
                                                        }}
                                                    >
                                                        Total Billable Pages
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                            paddingTop: "20px",
                                                        }}
                                                    >
                                                        {this.calculateTotal()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogFooter>
                        {!this.state.loading && (
                            <PrimaryButton
                                theme={getPrimaryGreenTheme()}
                                onClick={this.onReloadClick}
                                text="Recalculate"
                            />
                        )}
                        <PrimaryButton
                            theme={getPrimaryBlueTheme()}
                            onClick={this.onCloseClick}
                            text="Logout"
                        />
                    </DialogFooter>
                </Dialog>
            </Customizer>
        );
    }

    private onCloseClick() {
        if (this.props.onClose) {
            this.props.onClose.apply(null, null);
        }
    }

    private onReloadClick() {
        this.loadSubscription(true);
    }
}
