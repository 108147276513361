import React from 'react';
import { IRequestHeaderDetail } from "../../../../models/applicationState";
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

interface IRequestHeadersProps {
    headersJson: string,
    updateFlag: number
}

interface IRequestHeadersState {
    requestHeaderDetails: IRequestHeaderDetail[]
}

export default class RequestHeaders extends React.Component<IRequestHeadersProps, IRequestHeadersState> {

    constructor(props) {
        super(props);
        const requestHeaderDetails = this.getRequestHeaderDetails();

        this.state = {
            requestHeaderDetails
        };

        this.newHeader = this.newHeader.bind(this);
        this.onItemKeyChanged = this.onItemKeyChanged.bind(this);
        this.onItemValueChanged = this.onItemValueChanged.bind(this);
        this.deleteHeader = this.deleteHeader.bind(this);
        this.getRequestHeaderData = this.getRequestHeaderData.bind(this);
        this.getRequestHeaderDetails = this.getRequestHeaderDetails.bind(this);
    }

    private getRequestHeaderDetails = (): IRequestHeaderDetail[] => {
        let requestHeaderDetails: IRequestHeaderDetail[] = [];
        if (this.props.headersJson) {
            requestHeaderDetails = JSON.parse(this.props.headersJson) as IRequestHeaderDetail[];
        }
        else {
            requestHeaderDetails = [
                { id: 1, key: '', value: '' },
                { id: 2, key: '', value: '' },
                { id: 3, key: '', value: '' },
                { id: 4, key: '', value: '' },
            ]
        }
        return requestHeaderDetails;
    }
    
    componentDidUpdate(prevProps: Readonly<IRequestHeadersProps>) {
        if (prevProps.updateFlag !== this.props.updateFlag) {
            const requestHeaderDetails = this.getRequestHeaderDetails();
            this.setState({
                requestHeaderDetails: requestHeaderDetails
            });
        }
    }

    private newHeader = (): void => {
        let { requestHeaderDetails } = this.state;
        requestHeaderDetails.push({ id: requestHeaderDetails.length + 1, key: '', value: '' });
        this.setState({
            requestHeaderDetails
        });
    }

    onItemKeyChanged = (itemId: number, newItemKey: string) => {
        let { requestHeaderDetails } = this.state;
        let item = requestHeaderDetails.find(r => r.id === itemId);
        if (item) {
            item.key = newItemKey;
        }
        this.setState({
            requestHeaderDetails
        });
    }

    onItemValueChanged = (itemId: number, newItemValue: string) => {
        let { requestHeaderDetails } = this.state;
        let item = requestHeaderDetails.find(r => r.id === itemId);
        if (item) {
            item.value = newItemValue;
        }
        this.setState({
            requestHeaderDetails
        });
    }

    private deleteHeader = (itemId: number): void => {
        let { requestHeaderDetails } = this.state;
        requestHeaderDetails = requestHeaderDetails.filter(r => r.id !== itemId);
        this.setState({
            requestHeaderDetails
        });
    }

    public getRequestHeaderData = (): string => {
        let reqHeaders = this.state.requestHeaderDetails.filter(r => !!r.key);
        return JSON.stringify(reqHeaders);
    }

    render() {
        const { requestHeaderDetails } = this.state;

        return (
            <Stack {...{
                tokens: { childrenGap: 10, padding: 10 }
            }}>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                        <th><IconButton iconProps={{ iconName: 'Add' }} title="New" ariaLabel="New" onClick={this.newHeader} /></th>
                    </tr>
                    <tbody>
                        {requestHeaderDetails &&
                            requestHeaderDetails.map(item => {
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            <TextField placeholder="Key" value={item.key} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemKeyChanged(item.id, newValue)} />
                                        </td>
                                        <td>
                                            <TextField placeholder="Value" value={item.value} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.onItemValueChanged(item.id, newValue)} />
                                        </td>
                                        <td>
                                            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={() => this.deleteHeader(item.id)} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Stack>
        )
    }
}
