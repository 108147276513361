import { Label, Stack, TextField } from '@fluentui/react';
import React from 'react';

interface IResultSettingsTabState {

}

interface IResultSettingsTabProps {
    responseText: string,
    responseStatus: string,
    activeSettingsTab: string
}

export default class ResultSettingsTab extends React.Component<IResultSettingsTabProps, IResultSettingsTabState> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Stack
                tokens={{
                    childrenGap: 10
                }}
                style={this.props.activeSettingsTab !== 'Result' ? { display: 'none' } : {}}
            >
                <Stack.Item>
                    <Label>Status: {this.props.responseStatus}</Label>
                </Stack.Item>
                <Stack.Item>
                    <TextField multiline rows={14} resizable={false} value={this.props.responseText} readOnly />
                </Stack.Item>
            </Stack>
        )
    }
}

