import { ICompany, IFilterOption, IGetDocumentServiceModel } from "../../models/applicationState";
import ClientManagementService from "../../services/clientManagementService";

export default interface IClientManagementActions {
    getCompanies(): Promise<ICompany[]>;
    getDocumentServices(processor: string, startDate: Date, endDate: Date): Promise<IGetDocumentServiceModel[]>;
}

export function getCompanies(): Promise<ICompany[]> {
    return new Promise(async (resolve, reject) => {
        const clientManagementService = new ClientManagementService();
        clientManagementService.getCompanies()
            .then(companies => {
                if (companies) {
                    Promise.resolve(companies);
                }
            });        
    })
}

export function getDocumentServices(application?: string, domain?:string, processor?: string, startDate?: number, endDate?: number): Promise<IGetDocumentServiceModel[]> {
    return new Promise(async (resolve, reject) => {
        const clientManagementService = new ClientManagementService();
        clientManagementService.getDocumentServices(application, domain, processor, startDate, endDate)        
            .then(documentServices => {
                if (documentServices) {
                    resolve(documentServices);
                }
            });        
    });
}

export function getIntegrationFilterOptions(): Promise<IFilterOption[]> {
    return new Promise(async (resolve, reject) => {
        const clientManagementService = new ClientManagementService();
        clientManagementService.getIntegrationFilterOptions()        
            .then(processors => {
                if (processors) {
                    resolve(processors);
                }
            });        
    });
}


