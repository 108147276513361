import React from 'react';
import { Dropdown, IDropdownOption, Label, Stack } from '@fluentui/react';
import OAuth2Detail from './oAuth2Detail';
import { IAuthData, IValidationError } from '../../../../models/applicationState';

interface IAuthorizationSettingsTabState {
    authType: number
}

interface IAuthorizationSettingsTabProps {
    authType: number,
    credentialsJson: string,
    authEndpointUrl: string,
    activeSettingsTab: string,
    updateFlag: number,
    validationError: IValidationError
}

export default class AuthorizationSettingsTab extends React.Component<IAuthorizationSettingsTabProps, IAuthorizationSettingsTabState> {

    authenticationOptions = [
        { key: 0, text: 'No Authentication' },
        { key: 1, text: 'OAuth 2.0' }
    ];
    oAuth2Ref = null;

    constructor(props) {
        super(props);
        this.state = {
            authType: this.props.authType
        }
        this.onAuthenticationTypeChange = this.onAuthenticationTypeChange.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IAuthorizationSettingsTabProps>) {
        if (prevProps.updateFlag !== this.props.updateFlag) {
            this.setState({
                authType: this.props.authType
            });
        }
    }

    onAuthenticationTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        this.setState({
            authType: item.key as number
        });
    }

    public getAuthData = (): IAuthData => {
        let authData = this.oAuth2Ref.getAuthData() as IAuthData;
        authData.authType = this.state.authType;
        return authData;
    }

    render() {
        const { credentialsJson, authEndpointUrl, activeSettingsTab, updateFlag, validationError } = this.props;
        const { authType } = this.state;
        return (
            <Stack
                tokens={{
                    childrenGap: 10
                }}
                style={activeSettingsTab !== 'Authorization' ? { display: 'none' } : {}}
            >
                <Stack.Item align="stretch">
                    <Dropdown
                        label="Authentication Type"
                        selectedKey={authType}
                        onChange={this.onAuthenticationTypeChange}
                        placeholder="Select an option"
                        options={this.authenticationOptions}
                    />
                </Stack.Item>
                <div style={authType !== 1 ? { display: 'none' } : {}}>
                    <OAuth2Detail
                        credentialsJson={credentialsJson}
                        authEndpointUrl={authEndpointUrl}
                        ref={(el) => (this.oAuth2Ref = el)}
                        updateFlag={updateFlag}
                        validationError={validationError}
                    />
                </div>
            </Stack>
        )
    }
}
